import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { compareKeys } from "../../forms/tools";
import {
  GetWcFileCommandInput,
  ListWcFilesCommandInput,
  WcFile,
} from "@amzn/ttechclaimintakeservice-client";
import { listWcFilesService } from "src/api/files/listWcFiles";
import { getWcFileService } from "src/api/files/getWcFile";
import { deleteWcFileService } from "src/api/files/deleteWcFile";

import {
  AUTHORIZATION_FORM_DOCUMENT_TYPE,
  MEDICAL_PANEL_DOCUMENT_TYPE,
  STATE_FORM_DOCUMENT_TYPE,
} from "src/views/workersCompensationDocuments/constants";
import { uploadWcFile } from "src/api/files/uploadWcFile";

interface DocumentTypeState {
  [index: string]: any;
  files: WcFile[];
  fileUrl: string;
  filesRetrieved: boolean;
  gettingFiles: boolean;
  fileUrlRetrieved: boolean;
  gettingFileUrl: boolean;
  deletingFile: boolean;
  deleteFileSuccess: boolean;
  uploadingFiles: boolean;
  uploadFilesSuccess: boolean;
  listFilesError: string;
  getFileUrlError: string;
  deleteFileError: string;
  uploadFilesError: string;
}
const initialDocumentTypeState: DocumentTypeState = {
  files: [],
  fileUrl: "",
  filesRetrieved: false,
  gettingFiles: false,
  fileUrlRetrieved: false,
  gettingFileUrl: false,
  deletingFile: false,
  deleteFileSuccess: false,
  listFilesError: "",
  getFileUrlError: "",
  deleteFileError: "",
  uploadingFiles: false,
  uploadFilesSuccess: false,
  uploadFilesError: "",
};
// Define a type for the slice state
export interface WorkersCompensationDocumentsState {
  [index: string]: DocumentTypeState;
  [MEDICAL_PANEL_DOCUMENT_TYPE]: DocumentTypeState;
  [AUTHORIZATION_FORM_DOCUMENT_TYPE]: DocumentTypeState;
  [STATE_FORM_DOCUMENT_TYPE]: DocumentTypeState;
}
export const initialState: WorkersCompensationDocumentsState = {
  [MEDICAL_PANEL_DOCUMENT_TYPE]: initialDocumentTypeState,
  [AUTHORIZATION_FORM_DOCUMENT_TYPE]: initialDocumentTypeState,
  [STATE_FORM_DOCUMENT_TYPE]: initialDocumentTypeState,
};

export const verifyStateIsValid = (
  state: WorkersCompensationDocumentsState
) => {
  return compareKeys(state, initialState);
};
/*eslint-disable @typescript-eslint/no-unused-vars*/
export const listWcFiles = createAsyncThunk(
  "workersCompensationDocuments/listWcFiles",
  async (input: ListWcFilesCommandInput, thunkAPI: any) => {
    const response = await listWcFilesService(input);
    return { ...response, fileType: input.fileType };
  }
);

export const getWcFile = createAsyncThunk(
  "workersCompensationDocuments/getWcFile",
  async (input: GetWcFileCommandInput, thunkAPI: any) => {
    const response = await getWcFileService(input);
    return { ...response, fileType: input.fileType };
  }
);

export const deleteWcFile = createAsyncThunk(
  "workersCompensationDocuments/deleteWcFile",
  async (input: GetWcFileCommandInput, thunkAPI: any) => {
    const response = await deleteWcFileService(input);
    return { ...response, fileType: input.fileType };
  }
);

export const uploadMultipleFiles = createAsyncThunk(
  "workersCompensationDocuments/uploadMultipleFiles",
  async (
    { files, fileType }: { files: File[]; fileType: string },
    thunkAPI: any
  ) => {
    const responses = [];
    for (const file of files) {
      try {
        const response = await uploadWcFile(
          {
            key: file.name,
            contentLength: file.size,
            fileType,
          },
          file
        );
        responses.push(response);
      } catch (error) {
        return error;
      }
    }
    return { responses, fileType };
  }
);

/*eslint-enable @typescript-eslint/no-unused-vars*/

export const workersCompensationDocumentsSlice = createSlice({
  name: "workersCompensationDocuments",
  initialState,
  reducers: {
    resetWorkersCompensationDocumentsState: () => {
      return initialState;
    },
    setNewWorkersCompensationDocumentsState: (
      state: WorkersCompensationDocumentsState,
      action: PayloadAction<{
        newState: DocumentTypeState;
        fileType: string;
      }>
    ) => {
      const { newState, fileType } = action.payload;

      return { ...state, [fileType]: newState };
    },
    setValueInWorkersCompensationDocumentsState: (
      state: WorkersCompensationDocumentsState,
      action: PayloadAction<{
        key: string;
        value: string | boolean | File[];
        fileType: string;
      }>
    ) => {
      const { fileType, key, value } = action.payload;
      state[fileType][key] = value;
    },
  },
  extraReducers: (builder: any) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(
        listWcFiles.fulfilled,
        (
          state: WorkersCompensationDocumentsState,
          action: PayloadAction<any>
        ) => {
          const { files, fileType } = action.payload;
          state[fileType].files = files;
          state[fileType].gettingFiles = false;
          state[fileType].filesRetrieved = true;
        }
      )
      .addCase(
        listWcFiles.pending,
        (state: WorkersCompensationDocumentsState, action: any) => {
          const { fileType } = action.meta.arg;
          state[fileType].gettingFiles = true;
          state[fileType].listFilesError = "";
        }
      )
      .addCase(
        listWcFiles.rejected,
        (state: WorkersCompensationDocumentsState, action: any) => {
          const { fileType } = action.meta.arg;
          state[fileType].gettingFiles = false;
          state[fileType].filesRetrieved = false;
          state.listFilesError = action.error.message;
        }
      )

      .addCase(
        getWcFile.fulfilled,
        (
          state: WorkersCompensationDocumentsState,
          action: PayloadAction<any>
        ) => {
          const { fileType, downloadUrl } = action.payload;
          state[fileType].fileUrl = downloadUrl;
          state[fileType].fileUrlRetrieved = true;
          state[fileType].gettingFileUrl = false;
          state[fileType].getFileUrlError = "";
        }
      )
      .addCase(
        getWcFile.pending,
        (state: WorkersCompensationDocumentsState, action: any) => {
          const { fileType } = action.meta.arg;
          state[fileType].gettingFileUrl = true;
          state[fileType].getFileUrlError = "";
        }
      )
      .addCase(
        getWcFile.rejected,
        (state: WorkersCompensationDocumentsState, action: any) => {
          const { fileType } = action.meta.arg;

          state[fileType].fileUrlRetrieved = false;
          state[fileType].gettingFileUrl = false;
          state.getFileUrlError = action.error.message;
        }
      )

      .addCase(
        deleteWcFile.fulfilled,
        (
          state: WorkersCompensationDocumentsState,
          action: PayloadAction<any>
        ) => {
          const { fileType } = action.payload;
          state[fileType].deleteFileSuccess = true;
          state[fileType].deletingFile = false;
          state[fileType].deleteFileError = "";
        }
      )
      .addCase(
        deleteWcFile.pending,
        (state: WorkersCompensationDocumentsState, action: any) => {
          const { fileType } = action.meta.arg;
          state[fileType].deleteFileSuccess = false;
          state[fileType].deletingFile = true;
          state[fileType].deleteFileError = "";
        }
      )
      .addCase(
        deleteWcFile.rejected,
        (state: WorkersCompensationDocumentsState, action: any) => {
          const { fileType } = action.meta.arg;

          state[fileType].deleteFileSuccess = false;
          state[fileType].deletingFile = false;
          state[fileType].deleteFileError = action.error.message;
        }
      )
      .addCase(
        uploadMultipleFiles.fulfilled,
        (
          state: WorkersCompensationDocumentsState,
          action: PayloadAction<any>
        ) => {
          const { fileType } = action.payload;
          state[fileType].uploadFilesSuccess = true;
          state[fileType].uploadingFiles = false;
          state[fileType].uploadFilesError = "";
        }
      )
      .addCase(
        uploadMultipleFiles.pending,
        (state: WorkersCompensationDocumentsState, action: any) => {
          const { fileType } = action.meta.arg;
          state[fileType].uploadFilesSuccess = false;
          state[fileType].uploadingFiles = true;
          state[fileType].uploadFilesError = "";
        }
      )
      .addCase(
        uploadMultipleFiles.rejected,
        (state: WorkersCompensationDocumentsState, action: any) => {
          const { fileType } = action.meta.arg;

          state[fileType].uploadFilesSuccess = false;
          state[fileType].uploadingFiles = false;
          state[fileType].uploadFilesError = action.error.message;
        }
      );
  },
});

export const {
  resetWorkersCompensationDocumentsState,
  setValueInWorkersCompensationDocumentsState,
  setNewWorkersCompensationDocumentsState,
} = workersCompensationDocumentsSlice.actions;

export default workersCompensationDocumentsSlice.reducer;
