export const MEDICAL_PANEL_DOCUMENT_TYPE = "MEDICAL_PANEL";
export const STATE_FORM_DOCUMENT_TYPE = "STATE_FORM";
export const AUTHORIZATION_FORM_DOCUMENT_TYPE = "AUTHORIZATION_FORM";
export const WC_CLAIM_ATTACHMENT = "WC_CLAIM_ATTACHMENT";

export const DOCUMENT_TYPE_LABELS: { [key: string]: string } = {
  [MEDICAL_PANEL_DOCUMENT_TYPE]: "Preferred Provider Panels",
  [STATE_FORM_DOCUMENT_TYPE]: "State Forms",
  [AUTHORIZATION_FORM_DOCUMENT_TYPE]: "Authorization Forms",
};
