import {
  SITE_CONFIGURATION_CONSTANTS,
  GENERAL_FORM_CONSTANTS,
} from "../../constants";
import { CUSTOM_VALIDATION_TYPES, FormValidationType } from "../../types";

const WEBHOOKS_REGEX = new RegExp(
  /(https:\/\/hooks\.chime\.aws\/incomingwebhooks\/.+\?token.+)|(https:\/\/hooks\.slack\.com(\/workflows|\/triggers)\/.+\/.+\/.+)/
);

export const formFieldsValidation: FormValidationType = {
  [SITE_CONFIGURATION_CONSTANTS.CODE]: {
    required: { value: true },
    maxCharacters: { value: 6 },
    minCharacters: { value: 4 },
    changeToUpperCase: { value: true },
    allowedCharactersRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.ONLY_LETTERS_AND_NUMBERS_REGEX),
      message: "Please enter only numbers and letters for Site Code",
    },
  },
  [SITE_CONFIGURATION_CONSTANTS.NAME]: {
    required: { value: true },
  },
  [SITE_CONFIGURATION_CONSTANTS.UNIT_NUMBER]: {
    required: { value: true },
    maxCharacters: { value: 6 },
    minCharacters: { value: 1 },
    changeToUpperCase: { value: true },
  },
  [SITE_CONFIGURATION_CONSTANTS.ADDRESS_LINE_1]: {
    required: { value: true },
    maxCharacters: { value: 128 },
  },
  [SITE_CONFIGURATION_CONSTANTS.ADDRESS_LINE_2]: {
    required: { value: false },
    maxCharacters: { value: 128 },
  },
  [SITE_CONFIGURATION_CONSTANTS.CITY]: {
    required: { value: true },
    maxCharacters: { value: 128 },
  },
  [SITE_CONFIGURATION_CONSTANTS.STATE]: {
    required: { value: true },
  },
  [SITE_CONFIGURATION_CONSTANTS.ZIP_CODE]: {
    required: { value: true },
    matchRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.ZIP_CODE_REGEX),
      message: GENERAL_FORM_CONSTANTS.ZIP_CODE_INVALID_MESSAGE,
    },
    allowedCharactersRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.ONLY_NUMBERS_REGEX),
      message: GENERAL_FORM_CONSTANTS.ZIP_CODE_INVALID_CHARACTERS_MESSAGE,
    },
    maxCharacters: {
      value: 5,
      message: GENERAL_FORM_CONSTANTS.ZIP_CODE_MAX_CHARACTERS_MESSAGE,
    },
  },
  [SITE_CONFIGURATION_CONSTANTS.COUNTRY]: {
    required: { value: true },
  },
  [SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_ALIAS_LIST]: {
    required: {
      value: true,
      message: "At least one email alias is required to proceed",
    },
    allowedCharactersRegExp: {
      value: new RegExp(/((^-|(^-.*)|(.*-$))$|[^a-zA-Z0-9-+])/),
      message: `Please enter the email alias without "@amazon.com". Valid email aliases only include letters, numbers and dashes (except if present in the start or end).`,
    },
    changeToLowerCase: {
      value: true,
    },
    maxCharacters: {
      value: 50,
    },
    minCharacters: {
      value: 3,
    },
    [CUSTOM_VALIDATION_TYPES.MAX_ATTRIBUTES]: {
      value: 5,
      message:
        "A maximum of 5 email aliases are supported. Please limit audience to need to know stakeholders",
    },
    [CUSTOM_VALIDATION_TYPES.MIN_ATTRIBUTES]: {
      value: 1,
      message: "At least one email alias is required to proceed",
    },
    [CUSTOM_VALIDATION_TYPES.DOES_NOT_START_WITH]: {
      value: "workcomp",
      message: `Please enter an email alias that does not start with "workcomp"`,
    },
    [CUSTOM_VALIDATION_TYPES.DUPLICATE_ATTRIBUTES_NOT_ALLOWED]: {
      value: true,
      message: "Duplicate aliases are not allowed",
    },
    [CUSTOM_VALIDATION_TYPES.REMOVE_WHITE_SPACE]: {
      value: true,
    },
    [CUSTOM_VALIDATION_TYPES.DIFFERENT_THAN_SUBMITTER_ALIAS]: {
      value: true,
      message: "Product group user aliases are not allowed.",
    },
  },
  [SITE_CONFIGURATION_CONSTANTS.EMAIL_NOTIFICATION_REQUESTED_BY]: {
    required: { value: true },
    maxCharacters: { value: 11 },
    allowedCharactersRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.ONLY_LETTERS_AND_NUMBERS_REGEX),
      message: "Please enter only numbers and letters",
    },
  },
  [SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_WEBHOOK]: {
    required: { value: false },
    matchRegExp: {
      value: WEBHOOKS_REGEX,
      message: "Please enter a valid Slack or Chime Webhook",
    },
  },
  [SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_WEBHOOK_REQUESTED_BY]: {
    required: { value: true },
    maxCharacters: { value: 11 },
    allowedCharactersRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.ONLY_LETTERS_AND_NUMBERS_REGEX),
      message: "Please enter only numbers and letters",
    },
  },
};
