import React, { useEffect, useState } from "react";

import {
  Container,
  Header,
  SpaceBetween,
  Button,
  FormField,
  Grid,
  Select,
  SelectProps,
  FileUpload,
  FileUploadProps,
  Modal,
  Box,
  StatusIndicator,
  Alert,
  AlertProps,
} from "@amzn/awsui-components-react/polaris";
import {
  deleteWcFile,
  getWcFile,
  listWcFiles,
  setValueInWorkersCompensationDocumentsState,
  uploadMultipleFiles,
} from "src/views/workersCompensationDocuments/workersCompensationDocumentsSlice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { FileOption } from "src/views/workersCompensationDocuments/types";
import { convertWcFilesResponseToOptions } from "src/views/workersCompensationDocuments/utils";
import { DOCUMENT_TYPE_LABELS } from "src/views/workersCompensationDocuments/constants";
import SimLinkGenerator from "src/components/simLinkGenerator/SimLinkGenerator";
import { SIM_TYPES } from "src/components/simLinkGenerator/types";
import RetrievingFileList from "src/views/workersCompensationDocuments/RetrievingFileList";

const TEN_SECONDS_DELAY = 10;
interface DocumentManagerWrapperProps {
  title: string;
  description: string;
  documentType: string;
}
const DocumentManagerWrapper = ({
  title,
  documentType,
  description,
}: DocumentManagerWrapperProps) => {
  const dispatch = useAppDispatch();
  //Redux state
  const {
    files,
    fileUrl,
    gettingFiles,
    gettingFileUrl,
    listFilesError,
    deleteFileError,
    deleteFileSuccess,
    uploadFilesError,
    uploadFilesSuccess,
    uploadingFiles,
  } = useAppSelector(
    (state: RootState) => state.workersCompensationDocuments[documentType]
  );
  const [fileOptions, setFileOptions] = useState<FileOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<FileOption | null>(null);
  const [alert, setAlert] = useState<React.ReactNode | null>(null);
  const [alertType, setAlertType] = useState<AlertProps.Type>("error");
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [visible, setVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [fileUploadErrorText, setFileUploadErrorText] = useState<string | null>(
    null
  );
  /**
   * Get file list on load
   */
  useEffect(() => {
    dispatch(listWcFiles({ fileType: documentType }));
  }, []);

  useEffect(() => {
    const options = convertWcFilesResponseToOptions(files);
    options.sort((a: FileOption, b: FileOption) => {
      return a.label.localeCompare(b.label);
    });
    setFileOptions(options);
  }, [files]);

  /**
   * Trigger file download when url is received
   */
  useEffect(() => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
      dispatch(
        setValueInWorkersCompensationDocumentsState({
          key: "fileUrl",
          value: "",
          fileType: documentType,
        })
      );
    }
  }, [fileUrl]);

  useEffect(() => {
    if (deleteFileSuccess) {
      setAlertType("success");
      setTemporaryAlert(
        "File Deleted Successfully",
        "success",
        TEN_SECONDS_DELAY
      );
      dispatch(listWcFiles({ fileType: documentType }));
    }
  }, [deleteFileSuccess]);

  useEffect(() => {
    if (deleteFileError) {
      setAlertType("error");
      setTemporaryAlert(
        `There was a problem deleting the file: ${deleteFileError}`,
        "error",
        TEN_SECONDS_DELAY
      );
    }
  }, [deleteFileError]);

  useEffect(() => {
    if (uploadFilesSuccess) {
      setAlertType("success");
      setTemporaryAlert(
        "File(s) uploaded successfully",
        "success",
        TEN_SECONDS_DELAY
      );
      setFilesToUpload([]);
      dispatch(listWcFiles({ fileType: documentType }));
    }
  }, [uploadFilesSuccess]);

  useEffect(() => {
    if (uploadFilesError) {
      setAlertType("error");
      setTemporaryAlert(
        `There was a problem uploading the files: ${uploadFilesError}`,
        "error",
        TEN_SECONDS_DELAY
      );
    }
  }, [uploadFilesError]);

  const getFileUrl = (fileName?: string) => {
    if (!fileName) return;
    setAlert(null);
    dispatch(getWcFile({ fileName, fileType: documentType }));
  };

  const setTemporaryAlert = (
    message: string,
    alertType: AlertProps.Type,
    secondsDelay: number
  ) => {
    setAlert(message);
    setAlertType(alertType);
    setTimeout(() => {
      setAlert(null);
    }, secondsDelay * 1000);
  };

  const renderAlert = () => {
    if (alert) {
      return (
        <Alert
          type={alertType}
          data-testid="documentManagerAlert"
          dismissible={true}
          onDismiss={() => setAlert(null)}
        >
          {alert}
        </Alert>
      );
    }
  };
  const renderErrorListingFiles = () => {
    return (
      <SpaceBetween
        direction="vertical"
        size="xs"
        data-testid="listingFilesErrorAlertContainer"
      >
        <Alert type="error" data-testid="listingFilesErrorAlert">
          There was an error retrieving the files. Please reload the page or
          click on the button below to try again. If the error persists please
          open a{" "}
          <SimLinkGenerator
            simType={SIM_TYPES.REPORT_A_BUG}
            target="_blank"
            ariaLabel="Pressing this link will open a new tab where you will be able to create a ticket for the team to investigate this issue."
          >
            support ticket
          </SimLinkGenerator>{" "}
          to get help with this issue.
        </Alert>
        <Button
          variant="normal"
          onClick={() => {
            dispatch(listWcFiles({ fileType: documentType }));
          }}
        >
          Reload File List
        </Button>
      </SpaceBetween>
    );
  };
  const handleFileUpload = () => {
    setAlert(null);
    setFileUploadErrorText(null);
    const duplicateFiles = filesToUpload.filter((file) => {
      return fileOptions.some((option) => option.label === file.name);
    });
    if (duplicateFiles.length > 0) {
      setVisible(true);
      setFilesToUpload(duplicateFiles);
    } else {
      dispatch(
        uploadMultipleFiles({
          files: filesToUpload,
          fileType: documentType,
        })
      );
    }
  };
  return (
    <React.Fragment>
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => setDeleteModalVisible(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  dispatch(
                    uploadMultipleFiles({
                      files: filesToUpload,
                      fileType: documentType,
                    })
                  );
                  setVisible(false);
                }}
              >
                Upload
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Duplicate File Name Found"
      >
        One or more of the files that you have selected for upload have the same
        file name as a file on the current list, are you sure you want to upload
        these files? Files with the same file name will be replaced.
      </Modal>
      <Modal
        onDismiss={() => setDeleteModalVisible(false)}
        visible={deleteModalVisible}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => setDeleteModalVisible(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (selectedOption?.value) {
                    dispatch(
                      deleteWcFile({
                        fileName: selectedOption.value,
                        fileType: documentType,
                      })
                    );
                    setDeleteModalVisible(false);
                    setSelectedOption(null);
                  } else {
                    setTemporaryAlert(
                      "There was an error with the file selected",
                      "error",
                      TEN_SECONDS_DELAY
                    );
                  }
                }}
              >
                Delete
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Delete File"
      >
        Are you sure that you want to delete this file from the bucket? The file
        will be deleted permanently and cannot be recovered.
      </Modal>
      <Container header={<Header variant="h2">{title}</Header>}>
        <SpaceBetween size="m" direction="vertical">
          {renderAlert()}

          {listFilesError ? (
            renderErrorListingFiles()
          ) : gettingFiles ? (
            <RetrievingFileList />
          ) : (
            <SpaceBetween size="m" direction="vertical">
              <FormField label={description} stretch>
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                  <Select
                    options={fileOptions}
                    selectedOption={selectedOption}
                    onChange={({
                      detail,
                    }: {
                      detail: SelectProps.ChangeDetail;
                    }) => {
                      setSelectedOption(detail.selectedOption as FileOption);
                    }}
                    filteringType="auto"
                    placeholder="Select a file to download"
                    virtualScroll={true}
                  ></Select>
                  {selectedOption && (
                    <SpaceBetween direction="horizontal" size="xxs">
                      <Button
                        disabled={!selectedOption || gettingFileUrl}
                        onClick={() => {
                          getFileUrl(selectedOption?.value);
                        }}
                        loading={gettingFileUrl}
                      >
                        Download
                      </Button>
                      <Button
                        disabled={!selectedOption}
                        onClick={() => {
                          setDeleteModalVisible(true);
                        }}
                        variant="link"
                      >
                        <StatusIndicator type="error">Delete</StatusIndicator>
                      </Button>
                    </SpaceBetween>
                  )}
                </Grid>
              </FormField>
              <FormField label={`Upload ${DOCUMENT_TYPE_LABELS[documentType]}`}>
                <FileUpload
                  onChange={({
                    detail,
                  }: {
                    detail: FileUploadProps.ChangeDetail;
                  }) => {
                    for (const fileToCheck of detail.value) {
                      const duplicates = detail.value.filter(
                        (file: File) => file.name === fileToCheck.name
                      );
                      if (duplicates?.length > 1) {
                        setFileUploadErrorText(
                          "You cannot upload multiple files with the same name at the same time"
                        );
                        return;
                      }
                    }
                    setFileUploadErrorText(null);
                    setFilesToUpload(detail.value);
                  }}
                  value={filesToUpload}
                  i18nStrings={{
                    uploadButtonText: (e: any) =>
                      e ? "Choose files" : "Choose file",
                    dropzoneText: (e: any) =>
                      e ? "Drop files to upload" : "Drop file to upload",
                    removeFileAriaLabel: (e: any) => `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                  }}
                  multiple={true}
                  showFileLastModified
                  showFileSize
                  tokenLimit={100}
                  errorText={fileUploadErrorText}
                />
              </FormField>
              <Button
                disabled={!filesToUpload.length || uploadingFiles}
                variant="primary"
                loading={uploadingFiles}
                onClick={() => {
                  handleFileUpload();
                }}
              >
                Upload
              </Button>
            </SpaceBetween>
          )}
        </SpaceBetween>
      </Container>
    </React.Fragment>
  );
};
export default DocumentManagerWrapper;
