import { WcFile } from "@amzn/ttechclaimintakeservice-client";
import { FileOption } from "./types";

export const convertWcFilesResponseToOptions = (wcFilesList: WcFile[]) => {
  return (
    wcFilesList.reduce((result: FileOption[], file: WcFile) => {
      const tokenizedFileName = file?.fileName?.split("/") ?? [];
      const standaloneFileName =
        tokenizedFileName[tokenizedFileName?.length - 1];
      if (!standaloneFileName || !file.fileName) return result;
      result.push({
        value: file.fileName,
        label: standaloneFileName,
        lastUploadTimestamp: file.lastUploadTimestamp,
      });
      return result;
    }, []) ?? []
  );
};
