import React from "react";

import {
  SpaceBetween,
  ContentLayout,
  Header,
} from "@amzn/awsui-components-react/polaris";
import DocumentManagerWrapper from "./DocumentManagerWrapper";
import {
  AUTHORIZATION_FORM_DOCUMENT_TYPE,
  MEDICAL_PANEL_DOCUMENT_TYPE,
  STATE_FORM_DOCUMENT_TYPE,
} from "src/views/workersCompensationDocuments/constants";

const DocumentsManagement = () => {
  return (
    <ContentLayout
      maxContentWidth={1000}
      header={<Header>Admin Documents Management Page</Header>}
    >
      <SpaceBetween size="s" direction="vertical">
        <DocumentManagerWrapper
          title="Preferred Medical Providers"
          documentType={MEDICAL_PANEL_DOCUMENT_TYPE}
          description="Select a Medical Panel file to download or delete"
        />
        <DocumentManagerWrapper
          title="Authorization for Medical Evaluation"
          documentType={AUTHORIZATION_FORM_DOCUMENT_TYPE}
          description="Select an Authorization form to download or delete"
        />
        <DocumentManagerWrapper
          title="State Forms"
          documentType={STATE_FORM_DOCUMENT_TYPE}
          description="Select a State form to download or delete"
        />
      </SpaceBetween>
    </ContentLayout>
  );
};
export default DocumentsManagement;
